import { css } from '@/styled-system/css';
export const styledDesktopNav = css.raw({
  '& .column-container': {
    columns: 2,
    columnFill: 'auto',
    maxHeight: 550
  },
  '& .column-container .topic-group': {
    breakInside: 'avoid',
    lineHeight: '1.65',
    mb: '1rem',
    pageBreakInside: 'avoid',
    pr: '2rem'
  },
  '& .column-container .topic-group ul': {
    listStyle: 'none',
    margin: 0
  }
});