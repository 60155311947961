import { Link } from '@/components/ui/link/Link';
import { Text } from '@/components/ui/typography/Text';
import { Box, Container, Flex, Stack } from '@/styled-system/jsx';
import { FaXmark } from 'react-icons/fa6';
import { GoTriangleDown } from 'react-icons/go';
import { DesktopSubNav } from '@/components/ui/header/DesktopSubNav';
import { ToolsSection } from '@/components/ui/header/ToolsSection';
import { INavItem } from '@/types/navitem';
import dynamic from 'next/dynamic';
import { useEffect, useRef, useState } from 'react';
import { useHover } from '@/hooks/useHover';
const NavCTA = dynamic(() => import('@/components/ui/header/NavCTA'), {
  ssr: false
});
export default function NavPopover({
  navItem,
  mr
}: {
  navItem: INavItem;
  mr: number | string;
}) {
  const linkHoverTextColor = 'gray.800';
  const linkHoverBgColor = 'brand.periwinkle.800';
  const hasSubCategories = navItem.children && navItem.children.some(child => child.children);
  const [isOpen, setIsOpen] = useState(false);
  const popover = useRef(null);
  const popoverTrigger = useRef(null);
  const hovering = useHover(popover);
  const hoveringTrigger = useHover(popoverTrigger);
  useEffect(() => {
    if (!hovering) {
      setIsOpen(false);
    }
  }, [hovering]);
  useEffect(() => {
    if (hoveringTrigger) {
      setIsOpen(true);
    }
  }, [hoveringTrigger]);
  return <Box ref={popover}>
      <Box ref={popoverTrigger}>
        <Flex alignItems={'center'} borderBottomColor={'transparent'} borderBottomStyle={'solid'} borderBottomWidth={8} height={65} fontSize={'md'} fontWeight={500} color={'black'} cursor={'pointer'} mr={mr} style={{
        paddingTop: 8,
        paddingLeft: 3,
        paddingRight: 3,
        ...navItem.style
      }} _hover={{
        backgroundColor: linkHoverBgColor,
        color: linkHoverTextColor,
        textDecoration: 'none'
      }}>
          {navItem.href ? <Link alignItems={'center'} height="100%" href={navItem.href} color={'black'} display="flex" transition={'all .3s ease'} _groupHover={{
          color: 'gray.400'
        }} fontWeight={500} textDecoration={navItem.underline ? 'underline' : 'none'}>
              {navItem.label}
            </Link> : <Text transition={'all .3s ease'} _groupHover={{
          color: 'gray.400'
        }} fontWeight={500} textDecoration={navItem.underline ? 'underline' : 'none'}>
              {navItem.label}
            </Text>}
          <Box position={'relative'} left={'5px'} top={0}>
            <GoTriangleDown color={'black'} h={2} w={2} />
          </Box>
        </Flex>
      </Box>

      <Box className="popover" display={isOpen ? 'block' : 'none'} left="0" position="absolute" top="65px" width="100%">
        <Box border="1px solid #CBD5E0" boxShadow={'xl'} bg={'beige.500'} p={8}>
          <Container className={'box-1'} maxW={'7xl'} position={'relative'}>
            {hasSubCategories ?
          // Case 1: Submenu with categories
          <Flex direction={'row'}>
                <Box role={'group'} borderRight={'1px solid'} borderColor={'gray.300'} pr={'12'} flex={'1'}>
                  <ToolsSection />
                </Box>
                <Box role={'group'} borderRight={'1px solid'} borderColor={'gray.300'} px={'6'} flex={'2'}>
                  <Text as={'h5'} color={'black'} fontWeight={'600'} mb={2} textTransform={'uppercase'}>
                    {navItem.subHeading || 'Topics'}
                  </Text>
                  <Box className="column-container" style={navItem.columnStyle}>
                    {navItem.children.map(child => <DesktopSubNav key={child.label} labelMobileOnly={navItem.labelMobileOnly} {...child} />)}
                  </Box>
                </Box>

                <NavCTA navItem={navItem} />
              </Flex> :
          // Case 2: Submenu without categories (direct links)
          <Stack>
                {navItem.children.map(child => <Link key={child.label} href={child.href} p={2}>
                    {child.label}
                  </Link>)}
              </Stack>}

            <Box position={'absolute'} right={0} top={0}>
              <FaXmark color={'black'} cursor={'pointer'} h={6} onClick={() => setIsOpen(false)} w={6} />
            </Box>
          </Container>
        </Box>
      </Box>
    </Box>;
}