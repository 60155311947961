const STATES_DICT = {
  AL: {
    label: 'Alabama',
    value: 'AL'
  },
  AK: {
    label: 'Alaska',
    value: 'AK'
  },
  AZ: {
    label: 'Arizona',
    value: 'AZ'
  },
  AR: {
    label: 'Arkansas',
    value: 'AR'
  },
  CA: {
    label: 'California',
    value: 'CA'
  },
  CO: {
    label: 'Colorado',
    value: 'CO'
  },
  CT: {
    label: 'Connecticut',
    value: 'CT'
  },
  DE: {
    label: 'Delaware',
    value: 'DE'
  },
  DC: {
    label: 'DC',
    value: 'DC'
  },
  FL: {
    label: 'Florida',
    value: 'FL'
  },
  GA: {
    label: 'Georgia',
    value: 'GA'
  },
  HI: {
    label: 'Hawaii',
    value: 'HI'
  },
  ID: {
    label: 'Idaho',
    value: 'ID'
  },
  IL: {
    label: 'Illinois',
    value: 'IL'
  },
  IN: {
    label: 'Indiana',
    value: 'IN'
  },
  IA: {
    label: 'Iowa',
    value: 'IA'
  },
  KS: {
    label: 'Kansas',
    value: 'KS'
  },
  KY: {
    label: 'Kentucky',
    value: 'KY'
  },
  LA: {
    label: 'Louisiana',
    value: 'LA'
  },
  ME: {
    label: 'Maine',
    value: 'ME'
  },
  MD: {
    label: 'Maryland',
    value: 'MD'
  },
  MA: {
    label: 'Massachusetts',
    value: 'MA'
  },
  MI: {
    label: 'Michigan',
    value: 'MI'
  },
  MN: {
    label: 'Minnesota',
    value: 'MN'
  },
  MS: {
    label: 'Mississippi',
    value: 'MS'
  },
  MO: {
    label: 'Missouri',
    value: 'MO'
  },
  MT: {
    label: 'Montana',
    value: 'MT'
  },
  NE: {
    label: 'Nebraska',
    value: 'NE'
  },
  NV: {
    label: 'Nevada',
    value: 'NV'
  },
  NH: {
    label: 'New Hampshire',
    value: 'NH'
  },
  NJ: {
    label: 'New Jersey',
    value: 'NJ'
  },
  NM: {
    label: 'New Mexico',
    value: 'NM'
  },
  NY: {
    label: 'New York',
    value: 'NY'
  },
  NC: {
    label: 'North Carolina',
    value: 'NC'
  },
  ND: {
    label: 'North Dakota',
    value: 'ND'
  },
  OH: {
    label: 'Ohio',
    value: 'OH'
  },
  OK: {
    label: 'Oklahoma',
    value: 'OK'
  },
  OR: {
    label: 'Oregon',
    value: 'OR'
  },
  PA: {
    label: 'Pennsylvania',
    value: 'PA'
  },
  RI: {
    label: 'Rhode Island',
    value: 'RI'
  },
  SC: {
    label: 'South Carolina',
    value: 'SC'
  },
  SD: {
    label: 'South Dakota',
    value: 'SD'
  },
  TN: {
    label: 'Tennessee',
    value: 'TN'
  },
  TX: {
    label: 'Texas',
    value: 'TX'
  },
  UT: {
    label: 'Utah',
    value: 'UT'
  },
  VT: {
    label: 'Vermont',
    value: 'VT'
  },
  VA: {
    label: 'Virginia',
    value: 'VA'
  },
  WA: {
    label: 'Washington',
    value: 'WA'
  },
  WV: {
    label: 'West Virginia',
    value: 'WV'
  },
  WI: {
    label: 'Wisconsin',
    value: 'WI'
  },
  WY: {
    label: 'Wyoming',
    value: 'WY'
  }
};
export const STATES = new Map(Object.entries(STATES_DICT));