import { RefObject, useEffect, useRef, useState } from 'react';
interface IHoverOptions {
  enterDelay?: number;
  leaveDelay?: number;
}
const canHover = (): boolean => typeof window !== 'undefined' ? !window.matchMedia('(hover: none)').matches : false;
export function useHover(target: RefObject<HTMLElement> | null, options: IHoverOptions = {
  enterDelay: 200,
  leaveDelay: 100
}) {
  const {
    enterDelay,
    leaveDelay
  } = options;
  const [hovering, setHovering] = useState(false);
  const timeout = useRef<number | undefined>();
  const toggle = (which: boolean) => {
    if (!canHover()) return;
    const delay = which ? enterDelay : leaveDelay;
    window.clearTimeout(timeout.current);
    if (delay) {
      timeout.current = window.setTimeout(() => setHovering(which), delay);
    } else {
      setHovering(which);
    }
  };
  const handleMouseEnter = () => toggle(true);
  const handleMouseLeave = () => toggle(false);
  useEffect(() => {
    if (target?.current) {
      target.current.addEventListener('mouseenter', handleMouseEnter);
      target.current.addEventListener('mouseleave', handleMouseLeave);
    }
    return () => {
      if (target?.current) {
        target.current.removeEventListener('mouseenter', handleMouseEnter);
        target.current.removeEventListener('mouseleave', handleMouseLeave);
      }
      if (timeout?.current) {
        clearTimeout(timeout?.current);
      }
    };
  }, []);
  return hovering;
}